.loadingArea {
    z-index: 9991;
    position: fixed;
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    background-color: #101010;
    overflow: hidden;
}

.loading {
    position: absolute;
    background-image: url(../assets/img/loading.gif);
    background-repeat: no-repeat;
    background-size: contain;
    width: 800px;
    height: 600px;
    margin: -300px 0 0 -400px;
    left: 50%;
    top: 50%;
    overflow: hidden;
}